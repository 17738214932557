import React from "react";
import {Swiper, SwiperSlide} from "swiper/react";
import {A11y, Navigation, Pagination, Scrollbar} from "swiper";

const BonPlanContent = () => {

    return <>
        <div className="container content-space-t-3">
            <div className="d-sm-flex">
                <div
                    className="container d-sm-flex align-items-sm-center vh-sm-100 content-space-t-3 content-space-b-1 content-space-b-sm-3 content-space-sm-0">
                    <div className="row justify-content-md-between align-items-md-center flex-grow-1">
                        <div className="col-9 col-md-5 col-md-4 mb-5 mb-md-0">
                            <img className="img-fluid" src="/assets/img/others/Fidely.png" alt="SVG Illustration"/>
                        </div>
                        <div className="col-md-6">
                            <div className="mb-4">
                                <h1>Offre Parrainage</h1>
                                {/*<p>A chaque fois qu'un(e) de vos ami(es) passe une première commande chez Afrisends</p>
                                <p>Recevez tous les deux <span className="text-primary">10%</span> sur votre prochaine
                                    envoi !</p>*/}
                                <p>Je communique mon code client Afrisends à mon ami(e), qui doit le mentionner à son
                                    tour à Afrisends lors de sa première commande pour valider l’offre de parrainage</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="overflow-hidden">
                <div className="container content-space-t-1 content-space-t-lg-2">
                    <div className="row justify-content-lg-between">
                        <div className="col-md-4 mb-5 mb-md-0 content-space-t-lg-2">
                            <h2>FIDLY</h2>
                            <p>Pour récompenser votre fidélité , Afrisends lance sa carte de fidélité digital!</p>
                            <p>Le principe est simple, gagnez des points à chaque expédition et bénéficiez de -50% de
                                lors de votre 10ième expédition.</p>
                            <p>Demandez la votre lors de votre prochaine commande!</p>
                        </div>
                        <div className="col-md-8">
                            <div className="">
                                <div className="js-swiper-card-grid swiper swiper-equal-height ps-4">
                                    <div className="swiper-wrapper">
                                        <Swiper
                                            modules={[Navigation, Pagination, Scrollbar, A11y]}
                                            spaceBetween={30}
                                            slidesPerView={2}
                                            loop={true}
                                            navigation
                                            onSlideChange={() => console.log('slide change')}
                                            onSwiper={(swiper) => console.log(swiper)}
                                        >
                                            <SwiperSlide>
                                                <div className="swiper-slide pt-4 pb-8">
                                                    <div className="card card-transition">
                                                        <img className="card-img" src="/assets/img/others/cdf1.png"
                                                             alt="fidelity card"/>
                                                    </div>
                                                </div>
                                            </SwiperSlide>
                                            <SwiperSlide>
                                                <div className="swiper-slide pt-4 pb-8">
                                                    <div className="card card-transition">
                                                        <img className="card-img" src="/assets/img/others/cdf2.png"
                                                             alt="fidelity card"/>
                                                    </div>
                                                </div>
                                            </SwiperSlide>
                                        </Swiper>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
}

export default BonPlanContent;
