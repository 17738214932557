import React from "react";
import Layout from "../components/layout/Layout";
import BonPlanContent from "../components/BonPlanContent";
const BonPlan = () => {
    return <>
        <Layout title={'BonPlan'}>
            <BonPlanContent/>
        </Layout>
    </>
}
export default BonPlan;
